import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";

const ContactSuccessPage = ({ intl }) => {
  return (
    <Layout>
      <Background>
        <Hero>
          <MotifColumn>
            <h1>{intl.formatMessage({ id: "contact-success.title" })}</h1>
            <p>{intl.formatMessage({ id: "contact-success.text" })}</p>
          </MotifColumn>
        </Hero>
      </Background>
    </Layout>
  );
};

export default injectIntl(ContactSuccessPage);
